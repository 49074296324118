import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

//获取流程分页列表
export function getProcDefList(params) {
  return request({
    url: envInfo.bgApp.workflowPath + '/procdef/page/list',
    method: 'get',
    params,
  });
}

//挂起流程
export function suspendProcDef(params) {
  return request({
    url: envInfo.bgApp.workflowPath + '/procdef/suspend',
    method: 'post',
    data: params,
  });
}

//激活流程
export function activeProdDef(params) {
  return request({
    url: envInfo.bgApp.workflowPath + '/procdef/active',
    method: 'post',
    data: params,
  });
}

//激活流程
export function deleteProcDef(params) {
  return request({
    url: envInfo.bgApp.workflowPath + '/procdef/delete',
    method: 'post',
    data: params,
  });
}

//待办
export function getTodoTaskList(params) {
  return request({
    url: envInfo.bgApp.workflowPath + '/todo/page/list',
    method: 'get',
    params,
  });
}

//工作台 - 个人待办事项
export function getPersonalTodoList(params) {
  return request({
    url: envInfo.bgApp.workflowPath + '/examine/findExaminePage',
    method: 'get',
    params,
  });
}

/**
 * 流程管理接口 - 获取流程分类树(预发布)
 * @returns {Promise<Array>} 返回节点数组
 * @returns {Array} array[].nodeType - 节点类型(mode:流程模型 category:文件夹(分类))
 * @returns {string} array[].nodeId - 节点ID(当类型为mode时为modeId，当类型为category时为categoryId)
 * @returns {string} array[].parentNodeId - 父节点ID
 * @returns {string} array[].nodeName - 节点名称
 */
export function apiGetPreNodeTree() {
  return request({
    url: envInfo.bgApp.workflowPath + '/mode/preNodeTree',
    method: 'get',
  });
}
/**
 * 流程管理接口 - 保存文件夹节点
 */
function postCreateUpdateNode(params) {
  return request({
    url: envInfo.bgApp.workflowPath + '/mode/saveNode',
    method: 'post',
    data: params,
  });
}
/**
 * 流程管理接口 - 保存文件夹节点
 * @param {Object} params - 请求参数
 * @param {string} params.nodeType - 节点类型(category:文件夹(分类))
 * @param {Object} [params.categoryVO] - 文件夹分类对象(nodeType为category时使用)
 * @param {string} [params.categoryVO.categoryId] - 文件夹(分类)ID，新增时不传
 * @param {string} params.categoryVO.categoryName - 文件夹(分类)名称
 * @param {string} params.categoryVO.parentId - 父文件夹(分类)ID
 * @returns {Promise<void>} 无返回值
 */
export function apiPostSaveFolderNode(params) {
  return postCreateUpdateNode(params)
}
/**
 * 流程管理接口 - 保存分类节点
 * @param {Object} params - 请求参数
 * @param {string} params.nodeType - 节点类型(mode:流程模型)
 * @param {Object} [params.modeVO] - 流程模型对象(nodeType为mode时使用)
 * @param {string} [params.modeVO.modeId] - 流程模型ID，新增时不传
 * @param {string} params.modeVO.processName - 流程名称
 * @param {string} params.modeVO.processCode - 流程编码
 * @param {string} params.modeVO.categoryId - 文件夹(分类)ID
 * @param {('ENABLE'|'DISABLE')} params.modeVO.enableStatus - 启用状态
 * @param {('PUBLISH'|'DRAFT')} params.modeVO.modeStatus - 模型状态
 * @returns {Promise<void>} 无返回值
 */
export function apiPostSaveFileNode(params) {
  return postCreateUpdateNode(params)
}
/**
 * 流程管理接口 - 删除分类节点
 * @param {Object} params - 请求参数
 * @param {string} params.nodeId - 节点ID(当类型为mode时为modeId，当类型为category时为categoryId)
 * @param {string} params.nodeType - 节点类型(mode:流程模型 category:文件夹(分类))
 * @returns {Promise<void>} 无返回值
 */
export function apiDeleteNode(params) {
  return request({
    url: envInfo.bgApp.workflowPath + '/mode/deleteNode',
    method: 'post',
    data: params,
  });
}
/**
 * 流程管理接口 - 分类节点重命名
 * @param {Object} params - 请求参数
 * @param {string} params.nodeId - 节点ID(当类型为mode时为modeId，当类型为category时为categoryId)
 * @param {string} params.nodeType - 节点类型(mode:流程模型 category:文件夹(分类))
 * @param {string} params.nodeName - 节点名称
 * @returns {Promise<void>} 无返回值
 */
export function apiNodeRename(params) {
  return request({
    url: envInfo.bgApp.workflowPath + '/mode/nodeRename',
    method: 'post',
    data: params,
  });
}
/**
 * 流程管理接口 - 移动分类节点
 * @param {Object} params - 请求参数
 * @param {string} params.nodeId - 节点ID(当类型为mode时为modeId，当类型为category时为categoryId)
 * @param {string} params.parentNodeId - 父节点ID
 * @param {string} params.nodeType - 节点类型(mode:流程模型 category:文件夹(分类))
 * @returns {Promise<void>} 无返回值
 */
export function apiMoveNode(params) {
  return request({
    url: envInfo.bgApp.workflowPath + '/mode/moveNode',
    method: 'post',
    data: params,
  });
}



/**
 * 流程管理接口 - 复制流程模型
 * @param {Object} params - 请求参数
 * @param {string} params.modeId - 流程模型ID
 * @param {string} params.processCode - 流程编码
 * @param {string} params.processName - 流程名称
 * @returns {Promise<void>} 无返回值
 */
export function apiCopyMode(params) {
  return request({
    url: envInfo.bgApp.workflowPath + '/mode/copyMode',
    method: 'post',
    data: params,
  });
}
/**
 * 流程管理接口 - 查询发布记录
 * @param {Object} params - 请求参数
 * @param {string} params.modeId - 流程模型ID
 * @returns {Array} array[].versionId - 版本ID
 * @returns {string} array[].modeId - 流程模型ID
 * @returns {string} array[].processName - 流程名称
 * @returns {string} array[].processCode - 流程编码
 * @returns {('ENABLE'|'DISABLE')} array[].enableStatus - 启用状态
 * @returns {('PUBLISH'|'DRAFT')} array[].modeStatus - 模型状态
 * @returns {string} array[].publishDescription - 发布备注
 */
export function apiPublishRecord(params) {
  return request({
    url: envInfo.bgApp.workflowPath + '/mode/publishRecord',
    method: 'get',
    params: { modeId: params.modeId },
  });
}
/**
 * 流程管理接口 - 查询发布历史版本详情
 * @param {Object} params - 请求参数
 * @param {string} params.modeId - 流程模型ID
 * @param {string} params.versionId - 版本ID
 * @returns {Promise<Object>} 返回发布历史版本详情对象
 */
export function apiPublishRecordDetail(params) {
  return request({
    url: envInfo.bgApp.workflowPath + '/mode/publishRecord',
    method: 'get',
    params,
  });
}
/**
 * 流程管理接口 - 查询发布历史版本详情
 * @param {Object} params - 请求参数
 * @param {string} params.modeId - 流程模型ID
 * @param {string} params.versionId - 版本ID
 * @returns {Promise<Object>} 返回发布历史版本详情对象
 */ 
export function apiPublishHistoryDetail(params) {
  return request({
    url: envInfo.bgApp.workflowPath + '/mode/publishHistoryDetail',
    method: 'get',
    params,
  });
}
/**
 * 流程管理接口 - 发布流程
 * @param {Object} params - 请求参数
 * @param {string} params.nodeType - 节点类型(mode:流程模型)
 * @param {Object} [params.modeVO] - 流程模型对象(nodeType为mode时使用)
 * @param {string} [params.modeVO.modeId] - 流程模型ID，新增时不传
 * @param {('PUBLISH'|'DRAFT')} params.modeVO.modeStatus - 模型状态
 * @param {string} params.modeVO.processName - 流程名称
 * @param {string} params.modeVO.publishDescription - 发布备注
 * @returns {Promise<void>} 无返回值
 */
export function apiPublish(params) {
  return postCreateUpdateNode(params)
}
/**
 * 流程管理接口 - 禁用/启用
 * @param {Object} params - 请求参数
 * @param {string} params.nodeType - 节点类型(mode:流程模型)
 * @param {Object} [params.modeVO] - 流程模型对象(nodeType为mode时使用)
 * @param {string} [params.modeVO.modeId] - 流程模型ID，新增时不传
 * @param {('ENABLE'|'DISABLE')} params.modeVO.enableStatus - 启用状态
 * @returns {Promise<void>} 无返回值
 */
export function apiEnableDisable(params) {
  return postCreateUpdateNode(params)
}
/**
 * 流程管理接口 - 查询发布记录列表
 * @param {Object} params - 请求参数
 * @param {string} params.modeId - 流程模型ID
 * @returns {Promise<Object>} 返回发布记录列表对象
 */
export function apiPublishRecordList(params) {
  return request({
    url: envInfo.bgApp.workflowPath + '/mode/publishRecordList',
    method: 'get',
    params,
  });
}

/**
 * 流程管理接口 - 查询流程分类下的节点列表(预发布)
 * @param {Object} params - 请求参数
 * @param {string} params.categoryId - 文件夹(分类)ID
 * @returns {Promise<Array>} 返回节点列表数组
 * @returns {string} array[].nodeId - 节点ID(mode类型为modeId，category类型为categoryId)
 * @returns {string} array[].parentNodeId - 父节点ID
 * @returns {string} array[].nodeName - 节点名称
 * @returns {('mode'|'category')} array[].nodeType - 节点类型(mode:流程模型 category:文件夹)
 * @returns {('ENABLE'|'DISABLE')} array[].enableStatus - 启用状态(仅mode类型)
 * @returns {('PUBLISH'|'DRAFT')} array[].modeStatus - 模型状态(仅mode类型)
 * @returns {('Y'|'N')} array[].isPublished - 是否已发布(仅mode类型)
 * @returns {string} array[].description - 节点描述
 * @returns {string} array[].updateUserName - 最后更新人
 * @returns {string} array[].lastUpdateDate - 最后更新时间
 * @returns {('1'|'0')} array[].isProcess - 是否可以配流程(1:是 0:否)
 */
export function apiPreCategoryNodeList(params) {
  return request({
    url: envInfo.bgApp.workflowPath + '/mode/preCategoryNodeList',
    method: 'get',
    params,
  });
}

/**
 * 审批流管理接口说明
1.查询流程分类树(预发布) GET
https://sit.rlinkiot.com/saascloud/workflow/mode/preNodeTree
无参数
返回值说明
[{
"nodeType": "", //节点类型(mode:流程模型 category:文件夹(分类))
"nodeId": "", //节点ID(当类型为mode时为modeId，当类型为category时为categoryId)
"parentNodeId": "", //父节点ID
"nodeName": ""//节点名称
}]

2.查询流程分类下的节点列表(预发布) GET
https://sit.rlinkiot.com/saascloud/workflow/mode/preCategoryNodeList?categoryId=xxx
参数说明：
categoryId: 文件夹(分类)ID
返回值说明
[{
"nodeId": "", //节点ID(当类型为mode时为modeId，当类型为category时为categoryId)
"parentNodeId": "", //父节点ID
"nodeName": "", //节点名称
"nodeType": "", //节点类型(mode:流程模型 category:文件夹(分类))
"enableStatus": "", // 启用状态 启用/禁用（ENABLE/DISABLE） 节点类型mode专用
"modeStatus": "", // 模型状态 发布/草稿（PUBLISH/DRAFT） 节点类型mode专用
"isPublished": "", // 是否已发布（Y/N） 节点类型mode专用
"description": "", // 节点描述
}]

3.保存分类节点saveNode POST
https://sit.rlinkiot.com/saascloud/workflow/mode/saveNode POST
参数说明:
{
"nodeType": "", //节点类型(mode:流程模型 category:文件夹(分类))
// 根据类型取相应对象进行保存
"categoryVO": {
"categoryId": "", // 文件夹(分类)ID，新增时不用传此参数
"categoryName": "", // 文件夹(分类)名称
"parentId": "", // 父文件夹(分类)ID
},
"modeVO": {
"modeId": "", // 流程模型ID，新增时不用传此参数
"processName": "", // 流程名称
"processCode": "", // 流程编码
"categoryId": "", // 文件夹(分类)ID
"enableStatus": "", // 启用状态 启用/禁用（ENABLE/DISABLE） 节点类型mode专用
"modeStatus": "", // 模型状态 发布/草稿（PUBLISH/DRAFT） 节点类型mode专用
}
}
无返回值

4.删除分类节点 POST
https://sit.rlinkiot.com/saascloud/workflow/mode/deleteNode
参数说明：
{
"nodeId": "", //节点ID(当类型为mode时为modeId，当类型为category时为categoryId)
"nodeType": "", //节点类型(mode:流程模型 category:文件夹(分类))
}
无返回值

5.分类节点重命名 POST
https://sit.rlinkiot.com/saascloud/workflow/mode/nodeRename
参数说明:
{
"nodeId": "", //节点ID(当类型为mode时为modeId，当类型为category时为categoryId)
"nodeType": "", //节点类型(mode:流程模型 category:文件夹(分类))
"nodeName": "", //节点名称
}
无返回值

6.移动分类节点 POST
https://sit.rlinkiot.com/saascloud/workflow/mode/moveNode
参数说明:
{
"nodeId": "", //节点ID(当类型为mode时为modeId，当类型为category时为categoryId)
"parentNodeId": "", //父节点ID
"nodeType": "", //节点类型(mode:流程模型 category:文件夹(分类))
}
无返回值

7.流程分类移动至最前 POST
https://sit.rlinkiot.com/saascloud/workflow/mode/sortTop
参数说明:
{
"nodeId": "", //节点ID(当类型为mode时为modeId，当类型为category时为categoryId)
"parentNodeId": "", //父节点ID
"nodeType": "", //节点类型(mode:流程模型 category:文件夹(分类))
}
无返回值

8.查询预发布模型详情 GET
https://sit.rlinkiot.com/saascloud/workflow/mode/preFindOne?modeId=xxx
参数说明：
modeId: 流程模型ID
返回值说明：
{
"modeId": "", // 流程模型ID
"processName": "", // 流程名称
"processCode": "", // 流程编码
"categoryId": "",  // 文件夹(分类)ID
"categoryName": "", // 文件夹(分类)名称
"enableStatus": "", // 启用状态 启用/禁用（ENABLE/DISABLE） 节点类型mode专用
"modeStatus": "", // 模型状态 发布/草稿（PUBLISH/DRAFT） 节点类型mode专用
"processConfig": "", // 流程配置
"formConfig": "", //表单配置
}


9.查询发布记录 GET
https://sit.rlinkiot.com/saascloud/workflow/mode/publishRecord?modeId=xxx
参数说明：
modeId: 流程模型ID
返回值说明：
{
"versionId": "", // 版本ID
"modeId": "", // 流程模型ID

"processName": "", // 流程名称
"processCode": "", // 流程编码
"enableStatus": "", // 启用状态 启用/禁用（ENABLE/DISABLE） 节点类型mode专用
"modeStatus": "", // 模型状态 发布/草稿（PUBLISH/DRAFT） 节点类型mode专用
"publishDescription": "", // 发布说明
}

10.查询发布历史版本详情 GET
https://sit.rlinkiot.com/saascloud/workflow/mode/publishRecord?modeId=xxx&versionId=xxx
参数说明：
modeId: 流程模型ID
versionId: 版本ID
返回值说明：
{
"versionId": "", // 版本ID
"modeId": "", // 流程模型ID
"processName": "", // 流程名称
"processCode": "", // 流程编码
"enableStatus": "", // 启用状态 启用/禁用（ENABLE/DISABLE） 节点类型mode专用
"modeStatus": "", // 模型状态 发布/草稿（PUBLISH/DRAFT） 节点类型mode专用
"processConfig": "", // 流程配置
"formConfig": "", //表单配置
"publishDescription": "", // 发布说明
}




 */